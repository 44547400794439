import React from "react";

export function Donor ({ user }) {
  return (
    <div
      key={user.name}
      className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
    >
      <div className="flex-1 min-w-0">

          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{user.name}</p>
          <p className="text-sm text-gray-500">
            <span className="text-black">${user.amount}</span>
          </p>

      </div>
    </div>
  )
}
