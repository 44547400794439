const getRandomInteger = (min, max) => (
  Math.floor(Math.random() * (max - min + 1)) + min
)

export const generateId = () => {
  const now = new Date()
  const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)
  const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000)
  return utcSecondsSinceEpoch.toString() + getRandomInteger(100000000, 999999999).toString()
}

export const partition = (arr, filter) => {
  return arr.reduce(
    (r, e, i, a) => {
      r[filter(e, i, a) ? 0 : 1].push(e);
      return r;
    }, [
      [],
      []
    ]);
}
