import React, { useState } from 'react'
import { Container } from 'components/Container'
import { Link } from 'gatsby'
import FaqData from '../../content/faq.yml'
import {
  ArrowCircleDownIcon as DownArrow,
  ArrowCircleUpIcon as UpArrow,
} from '@heroicons/react/solid'

export function Faqs() {
  const data = FaqData.map((x, idx) => ({ ...x, key: `faq-${idx}` }))
  const splitAt = data.length / 2
  const faq = [data.slice(0, splitAt), data.slice(splitAt + 1)]

  const [faqHiddenState, setFaqHiddenState] = useState(
    data.reduce((current, { key }) => {
      return { ...current, [key]: true }
    }, {})
  )

  const handleClick = (event, key) => {
    event.preventDefault()

    setFaqHiddenState((prevState) => {
      return {
        ...prevState,
        [key]: !prevState[key],
      }
    })
  }

  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-white py-5"
    >
      <Container className="relative">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mx-auto lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Questions & Answers
            </h2>
            {/* <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, email our support team
            and if you’re lucky someone will get back to you.
          </p> */}
          </div>
          <div></div>
          <dl
            role="list"
            className="mx-auto mt-10 grid grid-cols-1 gap-x-8 lg:max-w-none lg:grid-cols-2 border-t lg:border-t-0 pt-3 lg:pt-0"
          >
            {faq.map((col) => {
              return (
                <div className="border-t-0 lg:border-t lg:pt-3">
                  {col.map((row) => {
                    return (
                      <div
                        key={row.key}
                        className="mb-5 border-b pb-3 border-b-slate-200"
                      >
                        <dt className="font-display text-lg leading-7 text-slate-900">
                          <Link
                            to={row.path}
                            onClick={(e) => handleClick(e, row.key)}
                          >
                            <div className="flex w-full justify-between space-x-6">
                              <span className="">{row.question}</span>
                              <span>
                                {faqHiddenState[row.key] ? (
                                  <DownArrow className="inline h-5 w-5 text-red-800" />
                                ) : (
                                  <UpArrow className="inline h-5 w-5 text-red-800" />
                                )}
                              </span>
                            </div>
                          </Link>
                        </dt>
                        <dd
                          className={`mt-2 text-base text-slate-700 ${
                            faqHiddenState[row.key] && 'hidden'
                          }`}
                        >
                          {row.answer}
                        </dd>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </dl>
        </div>
      </Container>
    </section>
  )
}
