import React from 'react'
import { Container } from 'components/Container'
import { StaticImage } from 'gatsby-plugin-image'

export function About() {
  return (
    <section
      id="about"
      aria-label="About Armand Hurford"
      className="relative overflow-hidden bg-white py-5"
    >
      <Container className="relative">
        <div className="bg-gray-50 overflow-hidden rounded-lg">
          <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:py-15 lg:px-8">
            <div className="text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <div>
                <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
                  Who is
                </h2>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Armand Hurford
                </h3>
              </div>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base max-w-prose md:max-w-none">
                  <figure>
                    <div>
                      <StaticImage
                        className="aspect-[7/3] lg:aspect-auto rounded-lg shadow-lg object-cover object-center"
                        src="../images/armand_hurford_path.jpg"
                        width={1184}
                        height={1700}
                        alt="Armand Hurford"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                {/* <div className="text-base max-w-prose lg:max-w-none">
              <p className="text-lg text-gray-500">
                I am the owner of a neighbourhood small business (Republic Bicycles), a father, a
                volunteer MC at Squamish Days Logger Sport, a curling enthusiast, and Squamish has
                been the home of my family for 4 generations. This gives me a deep understanding of
                where we have come from and a vested interest and vision of where we are going.
              </p>
            </div> */}
                <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <p className="mb-5">
                    I am a seasonal book-worm and a policy nerd that loves the
                    outdoors, and spend my time either camping, canoeing,
                    hiking, cycling or just being with my family. Also, I
                    greatly enjoy log rolling, nature photography, spirited
                    debate and live music.
                  </p>

                  <p className="my-5">
                    Squamish has been the home of my family for four
                    generations, giving me a deep understanding of its history
                    and a vested interest in its future. Over the past 4 years,
                    I have served my first term as a councillor with the
                    District of Squamish. In addition to this role, I have owned
                    and operated a local business for the past 15 years, and I
                    am a founding member of SORCA (Squamish Off-Road Cycling
                    Association) and a life-long volunteer with SquamishDays
                    Loggers Sports.
                  </p>

                  <p className="my-5">
                    I believe in finding common ground and building effective
                    working relationships to tackle the complicated issues of
                    our growing community.
                  </p>

                  {/* <h3 className="font-extrabold tracking-tight text-gray-900 pb-2">So, why vote for me?</h3>
              <p className="mb-5">
                I am here for the long haul. I am fully aware I will need to justify my decisions on council for the rest of my days. Those decisions will also directly affect my quality of life and most importantly that of my daughter, niece and nephews.
                I am open-minded person, my business experience has taught me the ability to listen and consider a wide-range of opinions and factors in order to make an informed and concise decision. It has also taught me negotiation techniques that enable me to work collaboratively, and to moderate different personalities to work together for the greater good.
              </p> */}
                  <p>
                    <a href="mailto:info@armandhurford.com">
                      Please contact me
                    </a>
                    , I want to hear from you!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
