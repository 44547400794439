import React, { useState } from 'react'
import { Container } from 'components/Container'
import Button from 'form/Button'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { normalizeEmail } from 'validator'

export function Help() {
  const [formData, setFormData] = useState({ email: '' })
  const [formReponse, setFormReponse] = useState({ result: 'pending', msg: '' })

  const onChange = (e) => {
    const { value, name } = e.target

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const email = normalizeEmail(formData.email)

    addToMailchimp(email, {
      'group[34674]': '4', // announcements group
    }).then((data) => {
      console.log(data)
      if (data.result === 'success') {
        setFormData({ email: '' })
      }
      setFormReponse(data)
    })
  }

  const signupForm = () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center">
      <div className="lg:w-0 lg:flex-1">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Keep Informed
        </h2>
        <p className="mt-3 max-w-3xl text-lg text-gray-500">
          We will keep you up to date with what is happening with our campaign
          and what you can do to help out!
        </p>
      </div>
      <div className="mt-8 lg:mt-0 lg:ml-8">
        <form onSubmit={handleSubmit} className="sm:flex">
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            value={formData.email}
            onChange={onChange}
            required
            className="w-full px-5 py-3 border border-gray-300 shadow-sm placeholder-gray-400 focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:max-w-xs rounded-md"
            placeholder="Enter your email"
          />
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <Button className="shadow-none mt-0 w-full flex justify-center py-3 px-5 text-base">
              Notify me
            </Button>
          </div>
        </form>
        <p className="mt-3 text-sm text-gray-500">
          {formReponse.result !== 'pending' && formReponse.msg}
        </p>
      </div>
    </div>
  )

  return (
    <section
      id="perspective"
      aria-label=""
      className="relative overflow-hidden bg-white py-5 mt-5"
    >
      <Container className="relative">
        <div className="bg-white">{signupForm()}</div>
      </Container>
    </section>
  )
}
