import React from "react";
import { twMerge } from "tailwind-merge";

const Button = ({ id, disabled, children, className = ""}) => {
  className = twMerge(`
    inline-flex
    items-center
    px-4
    py-2
    mt-4
    border
    border-transparent
    text-sm
    font-medium
    rounded-md
    shadow-sm
    text-white
    bg-red-600
    hover:bg-red-700
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-red-500
    `,
    className
  )

  return (
    <button disabled={disabled} id={id} className={className}>
      {children}
    </button>
  );
};

export default Button
