import React from 'react'

import Layout from 'components/Layout'
import { Container } from 'components/Container'
import Hero from 'components/Hero'
import { Donors } from 'components/Donors'
import { About } from 'components/About'
import { Perspective } from 'components/Perspective'
import { Faqs } from 'components/Faqs'
import { Help } from 'components/Help'
import { Events } from 'components/Events'
import { Media } from 'components/Media'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => (
  <Layout>
    <Container>
      <div className="relative bg-white">
        <div className="mb-5">
          <StaticImage
            className="rounded object-contain h-36 sm:h-48 md:h-64 lg:h-80"
            src="../images/armand_hurford_wide.jpg"
            width={3456}
            height={1629}
            alt="Armand Hurford"
          />
        </div>
      </div>
    </Container>
    <Hero />
    {/* <DonateButton /> */}
    <Perspective />
    <Media />
    <About />
    <Faqs />
    <Events />
    <Help />
    <Donors />
  </Layout>
)

export default IndexPage
export { Head } from 'components/Head'
