import React from "react";

export const Head = () => (
  <>
    <title>Armand Hurford for Squamish Mayor</title>
    <meta name="description" content="Armand Hurford's Squamish Mayor election campaign 2022" />
    <meta property="og:title" content="Elect Armand Hurford for Squamish Mayor" />
    <meta property="og:url" content="https://armandhurford.com" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="" />
  </>
)
