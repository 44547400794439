import React from 'react'
import { Container } from 'components/Container'

export function Media() {
  const posts = [
    {
      title:
        'Armand Hurford will be Squamish’s next mayor: preliminary results',
      href: 'https://www.squamishchief.com/2022-civic-election/armand-hurford-will-be-squamishs-next-mayor-preliminary-results-5961724',
      description: '',
      date: 'October 15th 2022',
      datetime: '2022-10-15',
      external: true,
      cta: 'View on the Squamish Chief',
    },
    {
      title: 'Armand Hurford elected Mayor of Squamish',
      href: 'https://www.squamishreporter.com/2022/10/15/armand-hurford-elected-mayor-of-squamish/',
      description: '',
      date: 'October 15th 2022',
      datetime: '2022-10-15',
      external: true,
      cta: 'View on the Squamish Reporter',
    },
    {
      title: 'Armand Hurford interview on myseatosky.org',
      href: 'https://myseatosky.org/election-2022/district-of-squamish/armand-hurford/',
      description: '',
      date: 'October 2022',
      datetime: '2022-10-01',
      external: true,
      cta: 'Read on myseatosky.org',
    },
    {
      title: 'Sea2skypodcast: Election 2022 Armand Hurford',
      href: 'https://sea2skypodcast.com/2022/10/05/election-2022-armand-hurford-mayoral-candidate/',
      description:
        'Squamish has been the home of Armand’s family for four generations, giving him a deep understanding of its history and a vested interest in its future. He has served as councilor for the last four years and owns and operates a local business for the past 15 years. He is a founding member of SORCA and a life-long volunteer with SquamishDays Loggers Sports. He truly believes in finding common ground and building effective working relationships to tackle the complicated issues of our growing community.',
      date: 'October 5th 2022',
      datetime: '2022-10-05',
      external: true,
      cta: 'Listen on sea2skypodcast.com',
    },
    {
      title: 'Squamish Chamber of Commerce: All Candidates Meeting',
      href: 'https://www.armandhurford.com/squamish-chamber-of-commerce-all-candidates/opening-statements',
      description:
        'Watch the Squamish Chamber of Commerce All Candidates Meeting',
      date: 'October 3rd 2022',
      datetime: '2022-10-03',
      external: false,
      cta: 'Watch on armandhurford.com',
    },
    {
      title: "Squamish votes 2022: Mayoral candidate Armand Hurford's Q&A",
      href: 'https://www.squamishchief.com/2022-civic-election/squamish-votes-2022-mayoral-candidate-armand-hurfords-qa-5871265',
      description:
        'Watch the elevator pitch and read an election Q&A of Armand Hurford, a mayoral candidate running for the District of Squamish.',
      date: 'Sep 26, 2022',
      datetime: '2020-09-26',
      external: true,
      cta: 'View on the Squamish Chief',
    },
    {
      title: 'Armand Hurford seeks the mayor’s office',
      href: 'https://www.squamishchief.com/local-news/armand-hurford-seeks-the-mayors-office-5766587',
      description:
        'The one-term Squamish councillor and owner of Republic Bicycles is aiming for the District’s top job',
      date: 'Sep 1, 2022',
      datetime: '2020-09-01',
      external: true,
      cta: 'View on the Squamish Chief',
    },
  ]

  return (
    <section
      id="press"
      aria-label=""
      className="relative overflow-hidden bg-white py-5"
    >
      <Container className="relative">
        <div className="px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Press
          </h2>
          <div className="grid gap-8 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {posts.map((post) => (
              <div key={post.title}>
                <p className="text-sm text-gray-500">
                  <time dateTime={post.datetime}>{post.date}</time>
                </p>
                <a
                  href={post.href}
                  target={post.external === true ? '_blank' : '_self'}
                  className="mt-2 block"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    {post.title}
                  </p>
                  {/* <p className="mt-3 text-base text-gray-500">
                    {post.description}
                  </p> */}
                </a>
                <div className="mt-3">
                  <a
                    href={post.href}
                    className="text-base font-semibold text-red-600 hover:text-red-500"
                    target={post.external === true ? '_blank' : '_self'}
                  >
                    {post.cta}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}
