import React from "react";
import { Container } from "components/Container";
import { partition } from "utils"

export function Events() {

  const events = [
    {
      title: 'Meet the candidate',
      description: 'Galileo Coffee from 11am to 2:30pm',
      date: 'Sept 4, 2022',
      datetime: '2022-09-04T09:00:00',
    },
    {
      title: 'Neighbourhood chat - Event #2',
      description: 'Cloudburst Cafe from 9am to 12pm',
      date: 'Sep 10, 2022',
      datetime: '2022-09-10T09:00:00',
    },
    {
      title: 'Neighbourhood chat - Event #3',
      description: 'Bean Brackendale from 9am to 12pm',
      date: 'Sep 11, 2022',
      datetime: '2022-09-11T09:00:00',
    },
    {
      title: 'Neighbourhood chat - Event #4',
      description: 'Smoke Bluff Coffee House from 8am to 11am',
      date: 'Sep 17, 2022',
      datetime: '2022-09-17T09:00:00',
    },
    {
      title: 'Neighbourhood chat - Event #5',
      description: 'Parklet downtown (corner of Cleveland & Winnipeg) from 9am to 12pm',
      date: 'Sep 18, 2022',
      datetime: '2022-09-18T09:00:00',
    },
    {
      title: 'Neighbourhood chat - Event #6',
      description: 'Cloudburst Cafe from 9am to 12pm',
      date: 'Sep 24, 2022',
      datetime: '2022-09-24T09:00:00',
    },
    {
      title: 'Neighbourhood chat - Event #7',
      description: 'Wonderlands Emporium from 9am to 12pm',
      date: 'Sep 25, 2022',
      datetime: '2022-09-25T09:00:00',
    },
  ]

  const [oldEvents, newEvents] = partition(events, (e) => Date.parse(e.datetime) < Date.now())

  return (
    <section
      id="events"
      aria-label=""
      className="relative overflow-hidden bg-white py-5"
    >
    <Container className="relative">
      <div className="bg-gray-50 overflow-hidden rounded-lg">
        <div className="max-w-7xl mx-auto py-5 md:py-10 px-4 sm:px-6 lg:py-15 lg:px-8">

          <div>
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Upcoming Events</h2>
            {
              newEvents.length === 0 &&
              <p className="mt-4 text-base text-gray-700">Stay tuned for more neighbourhood chats where you can meet Armand and ask him your questions.</p>
            }
          </div>
          <div className="mt-6 grid gap-5 md:grid-cols-2 lg:grid-cols-4">
            {newEvents.map((event) => (
              <div key={event.title}>
                <p className={`text-xl font-semibold text-gray-900`}>
                  <time dateTime={event.datetime}>{event.date}</time>
                </p>
                <div className="mt-2 block">
                  <p className="text-base text-gray-500">{event.title}</p>
                  <p className={`text-base text-gray-500`}><span className="text-red-500 font-bold">@</span> {event.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="md:mt-6" >
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Past Events</h2>
          </div>
          <div className="mt-4 grid gap-1 md:gap-5 md:grid-cols-4">
            {oldEvents.map((event) => (
              <div key={event.title}>
                <p className={` text-gray-900`}>
                  <time dateTime={event.datetime}>{event.date}</time>
                </p>
                <div className="block">
                  <p className="text-sm text-gray-500">{event.title}&nbsp;
                  <span className="text-red-500 font-bold">@</span> {event.description}</p>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </Container>
    </section>
  )
}
