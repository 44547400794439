const create = (data) => {
  return fetch('/.netlify/functions/donors-create', {
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const list = () => {
  return fetch('/.netlify/functions/donors-list').then((response) => {
    return response.json()
  })
}

const update = (id, data) => {
  return fetch(`/.netlify/functions/donors-update?id=${id}`, {
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

export default {
  create: create,
  list: list,
  update: update
}
