import React from "react";
import { Container } from "components/Container";

export default function Hero() {
  return (
    <section id="hero"
      aria-label=""
      className="relative sm:my-10 text-center"
    >
      <Container className="relative">
        <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
          Armand Hurford{' '}
          <span className="relative whitespace-nowrap text-red-600">
            <span className="relative">
              for Mayor{' '}

            </span>
            <svg fill="currentColor" className="inline h-8 w-8 sm:h-12 sm:w-12 text-red-500" viewBox="0 0 512 457.57">
            <path className="text-red-600" d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z"/>
          </svg>

          </span>{' '}
          {/* brighter Squamish */}
        </h1>
        {/* <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
          I am the owner of a neighbourhood small business, a father, a volunteer MC at Squamish Days Logger Sport, a curling enthusiast, and Squamish has been the home of my family for 4 generations. This gives me a deep understanding of where we have come from and a vested interest and vision of where we are going.
        </p> */}
      </Container>
    </section>
  )
}
