import React, { useState, useEffect } from 'react'
import { Container } from 'components/Container'
import { Link } from 'gatsby'

import { Donor } from 'components/Donor'
import DonorAPI from 'api/donors'

export function Donors() {
  const [data, setData] = useState(null)

  useEffect(() => {
    DonorAPI.list().then((users) => {
      return setData(users)
    })
  }, [])

  return (
    <section
      id="donors"
      aria-label=""
      className="relative overflow-hidden bg-white py-5"
    >
      <Container className="relative">
        <div className="bg-gray-50 overflow-hidden rounded-lg">
          <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:py-15 lg:px-8">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">
                Campaign Donors 🎉
              </h2>
              <p className="mt-4 text-base text-gray-500">
                Here are all the people who have donated to my campaign for
                election.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 pt-10">
              {data &&
                data.map((user) => <Donor key={user.name} user={user} />)}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
