import React from "react"
import { Container } from "components/Container";

export function Perspective() {
  return (
    <section
      id="perspective"
      aria-label=""
      className="relative overflow-hidden bg-white py-5 mt-5"
    >
    <Container className="relative">
      <div className="bg-gray-50 overflow-hidden rounded-lg">
      <div className="max-w-7xl mx-auto py-5 sm:py-10 px-4 sm:px-6 lg:py-15 lg:px-8">
        <dl className="space-y-5 lg:space-y-0 sm:grid sm:grid-cols-1 lg:grid-cols-3 lg:gap-x-8">

          <div className="relative">
            <dt>
              <p className="text-lg leading-6 font-medium text-gray-900">Affordability</p>
            </dt>
            <dd className="mt-2 text-base text-gray-500 space-y-5">
              <p>
                 Addressing Squamish’s core economic and livability challenges that the District of Squamish can have a meaningful impact on. We must:
              </p>
              <ul className="list-disc space-y-3 lg:list-outside pl-3">
                <li>Address high rent prices and low rental availability by adopting municipal policies that increase rental supply</li>
                <li>Advocate for provincial support for a regional transit solution</li>
                <li>Create incentives and reduce barriers to increased affordable childcare capacity to meet the demand of a growing base of young families Increase funding for better public transit to support affordable mobility in Squamish</li>
                <li>Increase funding for better public transit to increase affordable mobility in Squamish </li>
                <li>Ease the burden on taxpayers by getting more from developers towards infrastructure such as affordable housing, daycare space and public amenities</li>
              </ul>
            </dd>
          </div>

          <div className="relative">
            <dt>
              <p className="text-lg leading-6 font-medium text-gray-900">Sustainable growth</p>
            </dt>
            <dd className="mt-2 text-base text-gray-500 space-y-5">
              <p>
                A high percentage of our population has to leave Squamish on a daily basis for employment. We need to ensure we do not perpetuate the need to commute for employment. To do so will require:
              </p>
              <ul className="list-disc space-y-3 lg:list-outside pl-3">
                <li>Leveraging Squamish’s natural assets to attract business, investment and jobs. Squamish has all the attributes necessary to build its economic base by incentivizing innovative manufacturing companies, leading environmental industries and recreational service businesses to invest in Squamish as their home to grow and create jobs</li>
                <li>Ensuring building and housing development results in more than just housing but a net investment in Squamish’s public infrastructure necessary to support the people that live in Squamish today and in the future</li>
                <li>Balancing the need for growth with protecting and preserving the core social, cultural, recreational and environmental amenities and values that have drawn so many to live here.</li>
              </ul>
            </dd>
          </div>

          <div className="relative">
            <dt>
              <p className="text-lg leading-6 font-medium text-gray-900">Common ground</p>
            </dt>
            <dd className="mt-2 text-base text-gray-500 space-y-5">
              <p>
                Our newly elected mayor and council must work to find common ground to collaboratively and respectfully address the concerns and aspirations of all members of the town and of the Squamish Nation, and to ensure they see themselves reflected in the town’s processes and the future we are building for current and the future generations.
              </p>
              <ul className="list-disc space-y-3 lg:list-outside pl-3">
                <li>We must avoid polarization, division, and conflict</li>
                <li>We must base our decisions on a solid economic, technical, and environmental fact base with input about the hopes, concerns, and needs of our citizens</li>
                <li>We must work in Squamish’s interests and adhere to open and transparent processes for making decisions that are free from undue influence from lobbyists</li>
              </ul>
            </dd>
          </div>

        </dl>
        <dl className="mt-5 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-1 m:grid-cols-1 lg:grid-cols-1 lg:gap-x-8">
          <div className="relative">
            <dt>
              <p className="text-lg leading-6 font-medium text-gray-900">Climate and Squamish’s economy</p>
            </dt>
            <dd className="mt-2 text-gray-500 text-base space-y-3">
              <p>
                Squamish, like much of North America, is feeling the effects of climate change. While Squamish and its citizens need to do everything in their power to reduce greenhouse gas emissions, Squamish must also adapt to the changes that are underway.
              </p>
              <p>
                Squamish must filter its decisions and policies to ensure that they serve to both reduce emissions and ensure that Squamish’s capital investments and infrastructure are designed to withstand and protect  from forest fires, droughts, heat waves and severe unseasonal weather.
              </p>
              <p>
                Greenhouse gas reductions efforts and adapting our infrastructure to meet the impacts of a changing climate is good environmental and economic policy.
              </p>
            </dd>
          </div>

        </dl>
      </div>
      </div>
    </Container>
    </section>
  )
}






